import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { settingsPage } from './components/settings/SettingsPage';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import { HomePage } from './components/HomePage';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { AutoLogout } from '@backstage/core-components';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

/* Azure SSO provider */
import {
  configApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';

let azureSSOProvider = {
  id: 'microsoft-auth-provider',
  title: 'Microsoft Entra ID',
  message: 'Sign in with your Microsoft Entra ID account',
  apiRef: microsoftAuthApiRef,
}
/* Azure SSO provider */

/* Custom Theme */
import { UnifiedThemeProvider } from '@backstage/theme';
import { gluoLightTheme, gluoDarkTheme } from './theme/gluoTheme';
import { CssBaseline } from '@material-ui/core';
/* Custom Theme */

/* Score-card Plugin */
import { ScoreBoardPage } from '@oriflame/backstage-plugin-score-card';
/* Score-card Plugin */

// Create the sign in page with supported providers listed in the config
// auth.providers key. Currently only guest and microsoft are supported.
// The keys themself can be an empty object and do not need to be configured.
// They do however need to be configured for the backend implementation to work.
function configureSignInPage(props: any) {
  const configApi = useApi(configApiRef);
  let providers = [];
  if(configApi.has('auth.providers.guest')) {
    providers.push('guest');
  }
  if(configApi.has('auth.providers.microsoft')) {
    providers.push(azureSSOProvider);
  }
  console.log(providers);
  return (
    <SignInPage
      {...props}
      providers={providers}
    />
  );
}

const app = createApp({
  apis,
  components: {
    SignInPage: props => configureSignInPage(props)
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  /* Custom Theme */
  themes: [
    {
      id: 'gluolight',
      title: 'light',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={gluoLightTheme} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'gluodark',
      title: 'dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={gluoDarkTheme} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
  ],
  /* Custom Theme */
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />}>
      {settingsPage}
    </Route>
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />

    {/* Score-card Plugin */}
    <Route path="/score-board" element={<ScoreBoardPage />} />
    {/* Score-card Plugin */}
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AutoLogout 
      idleTimeoutMinutes={30}
      useWorkerTimers={false}
      logoutIfDisconnected={false}
    />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
