import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {
  InfoCard,
} from '@backstage/core-components';
import {
  HomePageCompanyLogo,
} from '@backstage/plugin-home';


function GluoHeader() {
  return (
    <img
      className="header"
      src="https://gluoweb.bruggke-gluo-io.apps.gluo.cloud/static-media/container-ship.png"
      alt="Shipping"
    />
  );
}

export const HomePage = () => (
  <div>
  <Box sx={{ 
    flexGrow: 1,
    pl: 12,
    pr: 12,
    pt: 12,
    pb: 12,
    width: '100%',
  }}>
  <Grid container spacing={2} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    <Grid item xs={12}>
      
      <HomePageCompanyLogo logo={<GluoHeader/>} />
      <InfoCard title="Cupola" subheader="The internal developer platform for DevOps Engineers">
        <p>Welcome! With Cupola you can rapidly create and deploy new software projects.</p>
        <p>Using this platform you don't need to worry about long and tedious setups to adhere to the best practices. All templates are equiped with secure defaults.</p>
      </InfoCard>
    </Grid>
    <Grid item xs={12}>
      <InfoCard title="Where to start" subheader="Creating new projects using the platform">
      	<h3>Developing</h3>
        <p>All work starts with a <i>base template</i>.</p>
        <p>To create a Gitlab Project (gitlabnese for Git repository), use the <b><a href="/create/templates/default/starter-template">Gitlab Project Template</a></b></p>
        <p>This template will allow you to create Docker images, Helm charts and Terraform modules!</p>
        <p>After creating a project, the portal will display a link to your new repository. Then you can take it from there.</p>
        <h3>Deploying</h3>
        <p>When you are ready to deploy stuff, first you will need to create an environment</p>
        <p>You do this by using the <b><a href="/create/templates/default/kubernetes-base-environment">Personal deployment environment Template</a></b>, which you can later select to deploy stuff to (typically container images)</p>
        <p>Once your environment is provisioned, you can deploy your own image there, using the <b><a href="/create/templates/default/kubernetes-helm-app">Kubernetes app Template</a></b>.</p>
        <p>If you don't want to create your own image, we also provide two one-click Apps:</p>
        <ul>
          <li><b><a href="/create/templates/default/kubernetes-wordpress-app">Wordpress</a></b></li>
          <li><b><a href="/create/templates/default/kubernetes-homer-app">Homer</a></b></li>
        </ul>
        <p>It's as easy as choosing a name, and clicking "next". After a few minutes, your app of choice will be available on the URL that is displayed by the portal</p>
        <p>You can browse the catalog, which lists all created<b><a href="/catalog">components and resources</a></b>.</p>
        <p>Enjoy!</p>
      </InfoCard>
    </Grid>
    <Grid item xs={12}>
      <InfoCard title="Further Reading">
        <p><b><a href="https://gitlab.com/gluobe/wiki" target="_blank">Gitlab Wiki</a></b></p>
      </InfoCard>
    </Grid>
  </Grid>
  </Box>
  </div>
);
